

































@import '~@/styles/variables';

.error404 {
  position: relative;
  background: $primary-brand-color;
  overflow: hidden;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url('~@/assets/work_5.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
