












































































































































.currentLanguage {
  &:hover {
    cursor: pointer;
  }
}
