










































































































































@import './src/styles/variables';

.menu-group-header {
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 16px;
  margin-bottom: 6px;
  > span {
    display: none;
    font-size: 0.75rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: initial;
    }
  }
}
